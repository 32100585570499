import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import { LineChart, Line, XAxis, YAxis, ReferenceLine, ResponsiveContainer } from "recharts";

function createData(
  ticker,
  volume,
  date,
  graphSeries
) {
  return {
    ticker,
    volume,
    date,
    graphSeries,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3,
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1,
      },
    ],
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow key={row.date+row.ticker+row.volume} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell align="center" component="th" scope="row">
          {row.ticker}
        </TableCell>
        <TableCell align="left">{row.number}</TableCell>
        <TableCell align="left">{"$"+row.purchaseBefore +" ➤ $"+ (row.complete ? row.priceSold : row.purchaseNow)+""}</TableCell>
        <TableCell align="center">{row.percentageOfPortfolio}%</TableCell>
        <TableCell align="center" >
    <LineChart
      width={window.innerWidth/3 + (open * window.innerWidth/5)}
      height={window.innerHeight/6 + (open * window.innerHeight/5)}
      data={row.graphSeries}
      margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
    >
      <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false} strokeWidth={3} />
      <XAxis dataKey="Month" />
      <YAxis width={77} />
      <ReferenceLine x={row.graphLine} label="" stroke="green" strokeWidth={2.5} />
      {row.complete == true && <ReferenceLine x={row.graphLineClosed} label="" stroke="red" strokeWidth={2} />}
    </LineChart>
        </TableCell>
        <TableCell align="right">{row.percentageChanged}%</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ShowChartIcon /> : <QueryStatsIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Order Date</TableCell>
                    <TableCell>Ticker</TableCell>
                    <TableCell align="right">Asset at purchase</TableCell>
                    <TableCell align="right">Asset now</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.date+row.ticker}>
                      <TableCell component="th" scope="row">
                        {row.date}
                      </TableCell>
                      <TableCell>{row.ticker}</TableCell>
                      <TableCell align="right">{row.assetBefore}</TableCell>
                      <TableCell align="right">
                      {row.assetNow}
                      </TableCell>
                    </TableRow>
                  
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Purchase</TableCell>
                    <TableCell>Value now</TableCell>
                    <TableCell align="right">Percent change</TableCell>
                    {!row.complete && <TableCell align="right">Percentage of asset held</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.purchaseBefore}>
                      <TableCell component="th" scope="row">
                        {row.purchaseBefore}
                      </TableCell>
                      <TableCell>{row.purchaseNow}</TableCell>
                      <TableCell align="right">{row.percentageChanged}</TableCell>
                      {!row.complete && <TableCell align="right">
                      {row.percentageOfPortfolio}
                      </TableCell>}
                    </TableRow>
                  
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell>Trade Profit</TableCell>
                    <TableCell>Dividends</TableCell>
                    <TableCell>Total Profit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.tradeProfit}>
                      <TableCell component="th" scope="row">
                        {row.tradeProfit}
                      </TableCell>
                      <TableCell>{row.dividends}</TableCell>
                      <TableCell>{row.profit}</TableCell>
                    </TableRow>

                </TableBody>
{row.complete &&
<>
                <TableHead>
                  <TableRow>
                    <TableCell>Sold Date</TableCell>
                    <TableCell>Price Sold</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={row.purchaseBefore}>
                      <TableCell component="th" scope="row">
                        {row.dateSold}
                      </TableCell>
                      <TableCell>{row.priceSold}</TableCell>
                    </TableRow>
                </TableBody>
</>
}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Orders({orders}) {
   
  var rows = orders;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Ticker</TableCell>
            <TableCell >Volume</TableCell>
            <TableCell >purchase(value now)</TableCell>
            <TableCell align="center">% of portfoio</TableCell>
            <TableCell align="center">History</TableCell>
            <TableCell align="right">% change</TableCell>
            <TableCell align="center">Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.date+row.number} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


import React from 'react';
import  API from '../Api';
import OrderModel from '../components/sections/OrderModel';
import Orders from '../components/elements/Orders';
import HeaderInfluencer from '../components/layout/HeaderInfluencer';
import Header from '../components/layout/HeaderLoggedIn';
import ReactGA from "react-ga4";
import ReactModal from "react-modal";
import { Button, View } from 'react-native';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

class Profile extends React.Component {
  constructor ({influencer}) {
    super();
    this.state = {
      isInfluencer : influencer,
      showModal: false,
      orders: [],
     ytdStats : [],
     filterButtons : [],
     filtersApplied : [],
     isLoading: true
    };
    ReactGA.initialize("G-WQP2CKSW84");

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  updateFilters(ticker){
    var filtersApplied = this.state.filtersApplied? this.state.filtersApplied : []
    let filtered = filtersApplied.includes(ticker);
    var currArray = filtersApplied;
    if(filtered){
      currArray = currArray.filter(e => e !== ticker)
      this.setState({ filtersApplied : currArray});
    }else{
      currArray.push(ticker);
      this.setState({ filtersApplied : currArray});
    }
  }

  filterButtons(){
    return(
      <Box
            sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            p: 1,
            m: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRadius: 1,
            margin: 1,
            }}>
      <Box style={{margin: 5}}>
        <p>Filter options</p>
      </Box>
      {this.state.filterButtons.map((d) => 
      <>
      <Box style={{margin: 5}}>
      <Button color={!this.state.filtersApplied.includes(d) ? "#52a36e" : "#8f605b"} onPress={() => this.updateFilters(d)}//)(this.state.filtersApplied.includes(d) ? this.setState({ filtersApplied : this.state.filtersApplied.filter(e => e !== d)}) : this.setState({ ...this.state.filtersApplied, d}))}
      title={!this.state.filtersApplied.includes(d) ? "remove " + d: "add " + d}/>
      </Box>
      </>
      )
    }
     </Box>
    
    )
  }

  handleOpenModal (index) {
    this.setState({ showModal: true , orderIndex : index});
  }

  handleCloseModal () {
    this.setState({ showModal: false });
  }

    componentDidMount(){
    this.setState({ isLoading: true });
    this.setState({ stu: 1 });
    API({
    url: '/profile'
    })
    .then(response => {
    if (response.statusText === "OK"){
    if (response.data['localytdStats'] != null){
      this.setState({ ytdStats:response.data['localytdStats'] });
      this.setState({ orders: response.data['orders'] });
      this.setState({ assets: response.data['assets'] });
      this.setState({ isLoading: false });
    }else{
      this.setState({ ytdStats:response.data['ytdStats'] });
      this.setState({ orders: response.data['orders'] });
      this.setState({ assets: response.data['assets'] });
      this.setState({ isLoading: false });
    }
    this.setState({ filterButtons: Array.from(new Set(response.data['orders'].map((item) => item.ticker)))});

    }});
    }

render () {
  return (
    <>
    <meta name="keywords" content="detailed statistics, benchmarking, auditing, orders, graphs" />
    <meta name="description" content="Detailed statistics" />
  <ThemeProvider theme={darkTheme}>
{ this.state.isInfluencer && <HeaderInfluencer navPosition="right" className="reveal-from-bottom" /> }
{ !this.state.isInfluencer && <Header navPosition="right" className="reveal-from-bottom" /> }
<main className="site-content">
<div style={{ paddingTop: '100px' }}>
{ this.state.isLoading &&
  <div className="App">Loading statistics...</div>
}
{ !this.state.isLoading &&
<>
          <h3 className="container-sm">Wealth History</h3>
            <TableContainer component={Paper} sx={{ p: 2 }}>
      <Table sx={{ minWidth: 200, borderSpacing: '0px 4px', borderCollapse: 'separate'}} aria-label="customized table">
        <TableHead>

          <TableRow                   style={{
                    height: 0,
                  }}>
            <TableCell align="center">Dates</TableCell>
            <TableCell align="center">Invested</TableCell>
            <TableCell align="center">Net Worth</TableCell>
            <TableCell align="center">Net Increase</TableCell>
            <TableCell align="center">Net Percentage</TableCell>
          </TableRow>
          </TableHead>
                  <TableBody>
          {this.state.ytdStats.map((row) => (
          <>
            <TableRow key={row.name}                   style={{
                    height: 0,
                  }}>
              <TableCell align="center" component="th" scope="row">
                {row.dates.substr(4,6) =="-01-01" && row.dates.substr(17,6) =="/12/31"? row.dates.substr(0,4) : row.dates}
              </TableCell>
              <TableCell align="center">${row.invested.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
              <TableCell align="center">${row.netWorth.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
              <TableCell align="center">${row.netIncrease.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
              <TableCell align="center">{row.netPercentage}%</TableCell>
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <h3 className="container-sm">Average Purchases</h3>
    <TableContainer component={Paper} sx={{ p: 2 }} >
      <Table sx={{ minWidth: 200, p: 1, borderSpacing: '0px 4px', borderCollapse: 'separate'}} aria-label="customized table">
        <TableHead>

          <TableRow                   style={{
                    height: 0,
                  }}>
            <TableCell align="center">Asset</TableCell>
            <TableCell align="center">Owned</TableCell>
            <TableCell align="center">Price Per Share</TableCell>
            <TableCell align="center">Average Buy</TableCell>
          </TableRow>
          </TableHead>
                  <TableBody>
          {this.state.assets.map((row) => (
          <>
            <TableRow key={row.asset}                   style={{
                    height: 0,
                  }}>
              <TableCell align="center">{row.asset}</TableCell>
              <TableCell align="center">{row.total.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
              <TableCell align="center">{row.pricePerShare != "Not available" && row.pricePerShare != undefined? "$"+row.pricePerShare.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "Not available"}</TableCell>
              <TableCell align="center">{row.averageBuy != "Not available" && row.averageBuy != undefined? "$"+row.averageBuy.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : "Not available"}</TableCell>
            </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    
      <h3 className="container-sm">Purchase Details</h3>
      { this.state.orders.length > 60 &&
        this.filterButtons()
      }
      <Orders orders={this.state.orders.filter( (obj ) => {
      return !this.state.filtersApplied.includes(obj.ticker);
      })}/>
        <div className="container-md">
        <div style={{ paddingTop: '20px' }}>
        <>
        <div style={{ paddingBottom: '50px' }}></div>
        <ReactModal
           isOpen={this.state.showModal}  style={{
    overlay: {
      position: 'fixed',
      top: 100,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '5px',
      left: '5px',
      right: '5px',
      bottom: '5px',
      border: '1px solid #ccc',
      background: '#000',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: '2px'
    }
  }}>

            <OrderModel order={this.state.orders[this.state.orderIndex]}/>
          <button onClick={this.handleCloseModal}>Close</button>
        </ReactModal>
            </>
            </div>
            </div>
            </>     
}
</div>
	  </main>
	  </ThemeProvider>
      </>
  );
  }
}

export default Profile;
